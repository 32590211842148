'use strict';

(function () {
  var self;
  class AddEditOrganizationModalCtrl {
    constructor($scope, $uibModalInstance, Site, User, Util, Organization, options, usSpinnerService) {
      this.Util = Util;
      this.Site = Site;
      this.User = User;
      this.Organization = Organization;
      this.usSpinnerService = usSpinnerService;
      this.$uibModalInstance = $uibModalInstance;
      this.isDisabled = false;
      this.errors = {};
      this.options = options;
      this.submitted = false;

      self = this;

      if (this.options.organization) {
        this.organization = angular.copy(this.options.organization);
        this.mode = 'UPDATE';
      } else {
        this.organization = {
          name: null
        };
        this.mode = 'CREATE';
      }

      $scope.$on('$stateChangeStart', function () {
        $uibModalInstance.dismiss();
      });
    }

    saveOrganization(form) {
      this.submitted = true;
      this.serverError = null;

      if (form.$valid) {
        this.isDisabled = true;
        this.startSpin('spinner-1');
        
        if (this.mode === 'CREATE') {
          this.Organization.save(this.organization).$promise
            .then(organization => {
              this.stopSpin('spinner-1');
              console.log(organization);
              this.$uibModalInstance.close(organization);
            })
            .catch(err => {
              this.isDisabled = false;
              this.errors = {};
              this.stopSpin('spinner-1');
              this.handleError(err, form);
            });
        } else {
          this.Organization.update(this.organization).$promise
            .then(organization => {
              this.stopSpin('spinner-1');
              console.log(organization);
              this.$uibModalInstance.close(organization);
            })
            .catch(err => {
              this.isDisabled = false;
              this.errors = {};
              this.stopSpin('spinner-1');
              this.handleError(err, form);
            });
        }
      }
    }

    handleError(err, form) {
      if (err && err.data && err.data.meta && err.data.meta.error_message) {
        if (err.data.meta.code && err.data.meta.code != 422 && !this.Util.isArray(err.data.meta.error_message)) {
          this.serverError = err.data.meta.error_message || 'Internal Server Error';
        } else {
          err.data.meta.error_message.forEach(errorMesssage => {
            for (let fieldName in errorMesssage) {
              if (form[fieldName]) {
                form[fieldName].$setValidity('mongoose', false);
                this.errors[fieldName] = errorMesssage[fieldName];
              }
            }
          })
        }
      } else if (err.data && err.data.message) {
        this.serverError = err.data.message;  
        console.log('validation err: 1', err);
      } else {
        this.serverError = 'Internal Server Error';
        console.log('validation err: 2', err);
      }
    }

    cancelSave() {
      this.$uibModalInstance.dismiss('cancel');
    }

    startSpin(spinner) {
      this.usSpinnerService.spin(spinner);
    }

    stopSpin(spinner) {
      this.usSpinnerService.stop(spinner);
    }
  }

  angular.module('windmanagerApp')
    .controller('AddEditOrganizationModalCtrl', AddEditOrganizationModalCtrl);
})();
